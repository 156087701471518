import Banner from '@components/Banner';
import clsx from 'clsx';
import { FluidObject } from 'gatsby-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { HTMLAttributes } from 'react';

export interface EventItemProps extends HTMLAttributes<HTMLDivElement> {
	children: string;
	title: string;
	date?: string;
	file: { childImageSharp: { fluid: FluidObject } };
}

const EventItem: React.FC<EventItemProps> = ({ title, date, file, children, ...rest }) => {
	return (
		<div className="w-full sm:w-1/2 md:w-1/3 p-2">
			<Banner
				image={{ fluid: file.childImageSharp.fluid }}
				className="p-3 text-center overflow-hidden flex"
				style={{
					minHeight: 200,
				}}
				overlayColor="bg-opacity-80 bg-black"
			>
				<div
					className="m-auto"
					style={{
						zIndex: 10,
					}}
					{...rest}
				>
					<h1 className="text-2xl md:text-3xl font-bold mb-1">{title}</h1>
					{date && (
						<h1 className={clsx('text-xl font-bold', children ? 'mb-0' : 'mb-1')}>{date}</h1>
					)}
					{/* TODO: a bit of a hacky solution to figure out if children is mdx or regular text */}
					{children && children.startsWith('function') ? (
						<MDXRenderer>{children}</MDXRenderer>
					) : (
						<div>{children}</div>
					)}
				</div>
			</Banner>
		</div>
	);
};

export default EventItem;
