import Banner from '@components/Banner';
import Countdown from '@components/Countdown';
import EventItem from '@components/Events/Item';
import EventList from '@components/Events/List';
import FAQItem from '@components/FAQ/Item';
import FAQList from '@components/FAQ/List';
import Page from '@components/Layout/Page';
import Link from '@components/Link';
import { graphql, useStaticQuery } from 'gatsby';
import { DateTime } from 'luxon';
import React from 'react';

const Events = () => {
	const { teamImage, faq, eventsData } = useStaticQuery(graphql`
		{
			teamImage: file(relativePath: { eq: "events/past/HackCUVI.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1300) {
						...GatsbyImageSharpFluid
					}
				}
			}

			faq: allFaqYaml {
				nodes {
					question
					answer
				}
			}

			eventsData: allEventsYaml(sort: { order: ASC, fields: date }) {
				nodes {
					description
					title
					type
					rawDate: date
					startDateTime
					date(formatString: "DD MMMM, YYYY")
					dateFromNow: date(fromNow: true)
					dateDisplay
					image {
						childImageSharp {
							fluid(maxWidth: 400) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`);
	const events = eventsData.nodes;

	// Get events in the future with valid start date/time (sorted by closest)
	const eventWithStarts = events
		.filter((event) => !!(event.startDateTime ?? event.rawDate))
		.map((event) => {
			const startString = event.startDateTime ?? event.rawDate;
			const start = DateTime.fromISO(startString);
			return { ...event, start };
		})
		.filter((event) => event.start.diffNow().toMillis() >= 0)
		.sort((a, b) => a.toMillis() - b.toMillis());

	// console.log({ events, closestEvents, filtered: closestEvents.filter(isFuture) });
	const hackathons = events.filter((event) => event.type === 'hackathon');
	const workshops = events.filter((event) => event.type === 'workshop');
	const pastEvents = events.filter((event) => event.type === 'past');

	return (
		<Page
			title="Events"
			banner={
				<>
					<div className="text-center py-2 bg-secondary-200">
						{eventWithStarts.length > 0 && (
							<>
								<h1 className="font-bold text-2xl text-center">
									NEXT EVENT: {eventWithStarts[0].title}
								</h1>
								<h2 className="font-bold text-2xl text-center">
									Starts in <Countdown start={eventWithStarts[0].start} />
								</h2>
							</>
						)}
					</div>

					<Banner image={{ fluid: teamImage.childImageSharp.fluid }}>
						<h1 className="text-5xl mb-3 font-bold">About Our Events</h1>
						<div className="max-w-2xl mx-auto space-y-3">
							<p>
								HackCU is our flagship 24-hour hackathon where more than 400 of the most passionate
								student developers come together to build mobile apps, websites, hardware devices,
								and other cool tools to show off their talent and skills!
							</p>
							<p>
								Along with HackCU, the organizing team hosts a variety of other hackathons such as
								Local Hack Day, Phase (our hardware hackathon), and many more! Additionally, we host
								workshops throughout the year to help students expand their skill sets and learn new
								things. Workshops feature a wide range of topics from landing your first tech
								internship to learning how to use Github to exploring biocomputation principles.
							</p>
						</div>
					</Banner>
				</>
			}
		>
			<div className="flex flex-col md:flex-row-reverse mt-4 flex-wrap">
				<div className="w-full sm:w-1/5">
					<div className="bg-secondary-500 mb-3 p-2 rounded-md">
						<h1 className="text-2xl font-bold mb-2">Volunteers / Mentors</h1>
						<p className="py-3">
							Since our events are remote this semester, we are currently not looking for volunteers
							or mentors. If you are interested in volunteering or mentoring with us in the future,
							please fill out our interest form! Please note that volunteers must be current CU
							students, and mentors should not currently be enrolled in college.
						</p>

						<Link
							className="block text-primary-500 font-bold"
							outside
							to="https://docs.google.com/forms/d/14Qy2o0wdFhBuuWvnqJ5bioyqz4z_mfxB_I7l1pXdHNc/edit?usp=sharing"
						>
							Mentor Interest Form
						</Link>
						<Link
							className="block text-primary-500 font-bold"
							outside
							to="https://docs.google.com/forms/d/1AjKx_inXWsvpeMgKi2IGGWA_53ChbTW061ULx0Ogccg/edit?usp=sharing"
						>
							Volunteer Interest Form
						</Link>
					</div>
				</div>
				<div className="w-full sm:w-4/5">
					<h1 className="text-5xl font-bold text-center sm:text-left">Hackathons</h1>

					<EventList>
						{hackathons.map(({ title, image, date, dateDisplay, description }) => (
							<EventItem key={title} title={title} date={dateDisplay ?? date} file={image}>
								{description}
							</EventItem>
						))}
					</EventList>

					<h1 className="text-5xl font-bold text-center sm:text-left">Workshops</h1>

					<EventList>
						{workshops.map(({ title, image, date, dateDisplay, description }) => (
							<EventItem key={title} title={title} date={dateDisplay ?? date} file={image}>
								{description}
							</EventItem>
						))}
					</EventList>

					{pastEvents.length > 0 && (
						<div>
							<h1 className="text-5xl font-bold text-center sm:text-left">Past Events</h1>
							<EventList>
								{pastEvents.map(({ title, image, date, dateDisplay, description }) => (
									<EventItem key={title} title={title} date={dateDisplay ?? date} file={image}>
										{description}
									</EventItem>
								))}
							</EventList>
						</div>
					)}
				</div>

				<div className="w-full">
					<h1 className="text-5xl font-bold text-center sm:text-left mb-3">FAQ</h1>
					<FAQList>
						{faq.nodes.map(({ question, answer }) => (
							<FAQItem key={question} question={question} answer={answer} />
						))}
					</FAQList>
				</div>
			</div>
		</Page>
	);
};

export default Events;
